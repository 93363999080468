<template>
  <div>
    <div class="row items-center justify-end no-wrap q-py-sm q-px-md">
      <div>
        <q-btn
          unelevated
          icon="filter_alt"
          size="md"
          color="primary"
          class="q-mr-sm"
          @click="dialogFilter = true"
        />
        <q-btn
          unelevated
          icon="add"
          size="md"
          color="primary"
          class="lt-sm"
          @click="addJournalEntry"
        />
        <q-btn
          unelevated
          icon="add"
          label="New Entry"
          size="md"
          color="primary"
          class="gt-sm"
          @click="addJournalEntry"
        />
      </div>
    </div>
    <q-separator />
    <div
      :style="`height: ${store.state.pageHeight - 103}px`"
      class="scroll q-pa-md"
    >
      <div v-if="dailyJournals.length === 0" class="text-center text-subtitle1">
        No journal entries to display.
      </div>

      <div
        v-for="(journal, index) in dailyJournals"
        :key="journal.id"
        class="row justify-center"
      >
        <q-card flat bordered class="q-mb-md q-pa-md" style="width: 600px">
          <div class="row justify-between">
            <div class="text-h6 text-primary">
              {{ journal.subject || '---' }}
            </div>
            <div>
              {{ new Date(journal.date * 1000).toLocaleDateString() }}
            </div>
          </div>

          <div class="q-pt-sm q-pb-sm">
            <span v-if="journal.keyword.length > 0">
              {{ journal.keyword }} -
            </span>

            <span v-if="expandedArr[index]">
              {{ journal.journal_entry }}
            </span>
            <span v-if="!expandedArr[index]">
              {{ journal.truncatedJournalEntry }}
            </span>

            <span v-if="journal.journal_entry.length > 200" class="q-pl-xs">
              ...
              <span
                class="text-primary cursor-pointer q-pl-xs"
                style="text-decoration: underline"
                @click="expandCollapse(index)"
              >
                <span v-if="!expandedArr[index]"> More </span>
                <span v-if="expandedArr[index]"> Less </span>
              </span>
            </span>
          </div>

          <div class="row justify-between items-end">
            <div class="col">
              <q-chip
                v-for="pondId in journal.pond_ids"
                :key="journal.id + pondId"
                class="q-mr-sm q-ml-none q-mb-none q-mt-sm"
                color="grey-3"
              >
                {{ pondNames[pondId] }}
              </q-chip>
            </div>

            <div
              class="col-auto row"
              :style="$q.screen.xs ? 'width: 73px' : 'width: 97px'"
            >
              <div class="q-pr-sm">
                <q-btn
                  unelevated
                  icon="image"
                  color="grey-3"
                  :text-color="
                    journal.attachments.length > 0 ? 'accent' : 'primary'
                  "
                  :padding="$q.screen.xs ? '3px 4px' : '3px 10px'"
                  @click="viewAttachments(journal)"
                />
              </div>
              <div>
                <q-btn
                  unelevated
                  icon="edit"
                  color="grey-3"
                  text-color="primary"
                  :padding="$q.screen.xs ? '3px 4px' : '3px 10px'"
                  @click="editJournalEntry(journal)"
                />
              </div>
            </div>
          </div>
        </q-card>
      </div>
    </div>

    <!-- Dialog for Journal Entry -->
    <q-dialog persistent position="top" v-model="dialogJournalEntry">
      <q-card style="width: 450px">
        <div class="row justify-center q-py-sm">
          <div class="text-h6 text-primary text-center">Journal Entry</div>
        </div>
        <q-separator color="primary" />

        <div class="q-pa-md">
          <q-select
            v-model="subjectSelected"
            outlined
            dense
            label="Select Subject"
            class="q-mb-md"
            :options="subjectOptions"
          />

          <q-input
            v-if="subjectSelected === 'Custom Subject'"
            v-model="customSubject"
            label="Custom Subject"
            class="q-mb-md"
            outlined
            dense
          />
          <q-input
            v-model="journalEntry.keyword"
            label="Keyword"
            class="q-mb-md"
            outlined
            dense
          />
          <q-input
            v-model="journalEntry.journal_entry"
            label="Journal Entry"
            class="q-mb-md"
            outlined
            dense
            type="textarea"
          />
          <q-input
            outlined
            dense
            label="Date"
            class="q-mb-md"
            v-model="journalEntry.date"
            lazy-rules
            :rules="[(val) => isDateValid(val) || 'Invalid Date']"
            hide-bottom-space
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer" color="primary">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="journalEntry.date" mask="MM/DD/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-select
            v-model="journalEntry.pond_ids"
            label="Select Pond/s"
            outlined
            :options="pondIds"
            multiple
            @input="setPondSelection"
          >
            <template v-slot:selected>
              <div
                v-if="
                  journalEntry.pond_ids[0] &&
                  (journalEntry.pond_ids[0] === 'all_ponds' ||
                    journalEntry.pond_ids[0] === 'no_pond_selected')
                "
              >
                <div v-if="journalEntry.pond_ids[0] === 'no_pond_selected'">
                  <div>No Pond Selected</div>
                </div>
                <div v-if="journalEntry.pond_ids[0] === 'all_ponds'">
                  <div>All Ponds</div>
                </div>
              </div>
              <div v-else>
                <q-chip
                  v-for="opt in journalEntry.pond_ids"
                  :key="opt"
                  removable
                  @remove="removePond(opt)"
                >
                  {{ pondNames[opt] }}
                </q-chip>
              </div>
            </template>
            <template v-slot:option="scope">
              <q-item
                v-bind="scope.itemProps"
                v-on="scope.itemEvents"
                class="justify-between items-center"
              >
                <div>
                  {{ pondNames[scope.opt] }}
                </div>
                <div>
                  <q-icon
                    v-if="journalEntry.pond_ids.includes(scope.opt)"
                    name="check"
                    size="20px"
                    color="primary"
                  />
                </div>
              </q-item>
              <q-separator />
            </template>
          </q-select>
        </div>
        <q-separator />
        <div class="row justify-end q-pa-md">
          <q-btn
            unelevated
            label="Cancel"
            color="grey-4"
            text-color="black"
            size="md"
            class="q-mr-sm"
            v-close-popup
          />
          <q-btn
            v-if="action === 'update'"
            unelevated
            label="Delete"
            color="grey-4"
            text-color="accent"
            class="q-mr-sm"
            @click="deleteJournalEntryConfirmA()"
          />
          <q-btn label="Submit" color="primary" @click="submitJournalEntry" />
        </div>
      </q-card>
    </q-dialog>

    <!-- filter dialog -->
    <q-dialog v-model="dialogFilter" full-height position="top">
      <q-card style="width: 450px">
        <div class="row justify-between q-pa-md">
          <div style="width: 40px"></div>
          <div class="row justify-center text-h6 text-center">Filter</div>
          <div>
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator color="primary" />
        <div class="q-px-md">
          <q-checkbox
            v-model="noPondSelected"
            label="No Pond Selected"
            color="primary"
            @input="setPondFilterList()"
          />
        </div>
        <q-separator />
        <div class="q-px-md">
          <q-checkbox
            v-model="allPonds"
            label="All Ponds"
            color="primary"
            @input="setPondFilterList()"
          />
        </div>
        <q-separator />
        <div
          class="scroll q-px-md q-pb-sm"
          :style="`max-height: ${store.state.pageHeight - 140}px`"
        >
          <div
            v-for="pond in filteredPonds"
            :key="pond.id + 'pondFilter'"
            clickable
          >
            <q-checkbox
              v-model="pond.checked"
              color="primary"
              :label="pond.name"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment display -->
    <Attachments
      v-if="displayAttachments"
      :parentObj="journalEntry"
      :dailyJournalId="journalEntry.id"
      :parentType="'dailyJournal'"
      @closeAttachments="displayAttachments = false"
    />
  </div>
</template>

<script>
import Attachments from '@/components/general/Attachments.vue';

import { smartCompare } from '@/lib/helpers';
import { date } from 'quasar';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { cloneObj, upperCaseFirst } from '@/lib/helpers';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';

const defaultJournalEntry = () => {
  return {
    id: null,
    farm_id: store.state.farm.id,
    pond_ids: ['no_pond_selected'],
    subject: '',
    journal_entry: '',
    keyword: '',
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    guid: null,
    attachments: [],
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id,
      parentType: 'dailyJournal',
      dailyJournalId: null
    }
  };
};

export default {
  name: 'DailyJournal',
  components: {
    Attachments
  },
  data() {
    return {
      action: 'create',
      allPonds: true,
      customSubject: '',
      dailyJournals: [],
      dateCopy: null,
      dateCopyStr: '',
      dialogFilter: false,
      dialogJournalEntry: false,
      displayAttachments: false,
      expandedArr: [],
      filteredPonds: [],
      isDateValid,
      journalEntry: defaultJournalEntry(),
      noPondSelected: true,
      parseTimeStamp,
      pondIds: [],
      pondList: [],
      pondNames: {},
      store,
      subjectOptions: [
        'Farm Notes - General',
        'Equipment Maintenance',
        'Water Analysis',
        'Fish Health',
        'Re-seine / Cleanout',
        'Custom Subject'
      ],
      subjectSelected: 'Farm Notes - General'
    };
  },
  mounted() {
    this.setPondList();
  },
  methods: {
    addJournalEntry() {
      this.action = 'create';
      this.journalEntry = defaultJournalEntry();
      this.subjectSelected = 'Farm Notes - General';
      this.customSubject = '';

      this.dialogJournalEntry = true;
    },
    deleteJournalEntryConfirmA() {
      if (this.journalEntry.attachments.length > 0) {
        this.$q.dialog({
          title: 'Attachments Not Deleted',
          message: `This journal entry has attachments. Attachments must be
              deleted before the journal entry can be deleted.`,
          ok: {
            label: 'OK',
            color: 'primary'
          },
          focus: 'none',
          persistent: true
        });

        return;
      }

      this.deleteJournalEntryConfirmB();
    },
    deleteJournalEntryConfirmB() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: `Are you sure you want to delete this journal entry
            from ${this.journalEntry.date}?`,
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';

          this.submitJournalEntry();
        });
    },
    editJournalEntry(journalEntry) {
      this.action = 'update';

      this.journalEntry = {
        ...defaultJournalEntry(),
        ...cloneObj(journalEntry)
      };

      this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;

      this.subjectSelected = this.subjectOptions.includes(journalEntry.subject)
        ? journalEntry.subject
        : 'Custom Subject';

      this.customSubject = journalEntry.subject;

      this.dateCopy = this.journalEntry.date;
      this.journalEntry.date = date.formatDate(
        new Date(journalEntry.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.journalEntry.date;

      this.dialogJournalEntry = true;
    },
    expandCollapse(index) {
      this.$set(this.expandedArr, index, !this.expandedArr[index]);
    },
    removePond(pondId) {
      this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
        (x) => x !== pondId
      );

      this.setPondSelection();
    },
    setDailyJournals() {
      this.dailyJournals = cloneObj(this.DailyJournals);

      for (let i = 0; i < this.dailyJournals.length; i++) {
        const journal = this.dailyJournals[i];

        this.expandedArr.push(false);
        journal.truncatedJournalEntry = journal.journal_entry.slice(0, 200);
      }
    },
    setPondSelection() {
      if (this.journalEntry.pond_ids.length === 0) {
        this.journalEntry.pond_ids = ['no_pond_selected'];
        return;
      }

      const selected =
        this.journalEntry.pond_ids[this.journalEntry.pond_ids.length - 1];

      if (selected === 'no_pond_selected') {
        this.journalEntry.pond_ids = ['no_pond_selected'];
      } else if (selected === 'all_ponds') {
        this.journalEntry.pond_ids = ['all_ponds'];
      } else {
        this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
          (x) => x !== 'no_pond_selected'
        );
        this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
          (x) => x !== 'all_ponds'
        );
      }
    },
    setPondFilterList() {
      this.filteredPonds = [];

      for (const pond of this.Ponds) {
        const pondObj = cloneObj(pond);
        pondObj.checked = this.allPonds;
        this.filteredPonds.push(pondObj);
      }
    },
    setPondList() {
      this.pondList = [];
      this.pondIds = [];
      this.pondNames = {};

      this.pondList.push(
        { label: 'No Pond Selected', value: 'no_pond_selected' },
        { label: 'All Ponds', value: 'all_ponds' }
      );
      this.pondIds.push('no_pond_selected');
      this.pondIds.push('all_ponds');
      this.pondNames['no_pond_selected'] = 'No Pond Selected';
      this.pondNames['all_ponds'] = 'All Ponds';

      for (const pond of this.Ponds) {
        const pondObj = cloneObj(pond);
        pondObj.value = pond.id;
        this.pondList.push(pondObj);
        this.pondIds.push(pond.id);
        this.pondNames[pond.id] = pond.name;
      }

      this.setPondFilterList();
    },
    submitJournalEntry() {
      const journalEntry = cloneObj(this.journalEntry);

      journalEntry.subject =
        this.subjectSelected === 'Custom Subject'
          ? upperCaseFirst(this.customSubject)
          : this.subjectSelected;

      const dateStr = this.journalEntry.date;
      journalEntry.date = setTimeOfDay(dateStr);

      journalEntry.keyword = journalEntry.keyword.toUpperCase();

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        journalEntry.date = this.dateCopy;
      }

      if (this.action === 'create') {
        journalEntry.id = 0;
      }

      journalEntry.guid = getUID();
      journalEntry.user_id = store.state.user.user_id;
      journalEntry.storeInfo.action = this.action + 'JournalEntry';
      journalEntry.storeInfo.category = 'dailyJournals';

      guidMatchHelper(journalEntry, this.action);

      store.dispatch('publish', journalEntry);

      this.dialogJournalEntry = false;
    },
    viewAttachments(journalEntry) {
      this.journalEntry = {
        ...defaultJournalEntry(),
        ...cloneObj(journalEntry)
      };

      this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;

      this.displayAttachments = true;
    }
  },
  computed: {
    DailyJournals() {
      const journals = cloneObj(this.$store.state.dailyJournals);

      return journals
        .filter((x) => {
          if (this.allPonds && x.pond_ids.includes('all_ponds')) {
            return true;
          }

          if (this.noPondSelected && x.pond_ids.includes('no_pond_selected')) {
            return true;
          }

          return x.pond_ids.some((y) => {
            return this.filteredPonds.some((z) => z.id === y && z.checked);
          });
        })
        .sort((a, b) => b.date - a.date);
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  },
  watch: {
    DailyJournals() {
      this.setDailyJournals();

      //replace this.journalEntry with the updated journal entry
      if (this.journalEntry.storeInfo.dailyJournalId) {
        const journalEntry = this.dailyJournals.find(
          (x) => x.id === this.journalEntry.storeInfo.dailyJournalId
        );

        if (journalEntry) {
          this.journalEntry = {
            ...defaultJournalEntry(),
            ...journalEntry
          };

          this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;
        }
      }
    },
    Ponds() {
      this.setPondList();
    }
  }
};
</script>

<style></style>
