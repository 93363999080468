import Vue from 'vue';
import VueRouter from 'vue-router';
import Feed from '../views/dataEntry/FeedHome.vue';
import Treat from '../views/dataEntry/Treat.vue';
import Oxygen from '../views/dataEntry/Oxygen.vue';
import Flavor from '../views/dataEntry/Flavor.vue';
import DailyJournal from '../views/dataEntry/DailyJournal.vue';

// Admin
import FeedDashboard from '../views/admin/FeedDashboard.vue';
import Ponds from '../views/admin/Ponds.vue';
import Images from '../views/admin/Images.vue';
import Calendar from '../views/admin/Calendar.vue';
import Reports from '../views/admin/Reports.vue';

// Settings
import SettingsMain from '../views/settings/SettingsMain.vue';

// import Settings from '../views/Settings.vue';
import SettingsBins from '../views/settings/bins.vue';
import SettingsTrucks from '../views/settings/feeders.vue';
import SettingsPonds from '../views/settings/ponds.vue';
import SettingsFeedMills from '../views/settings/feed-mills.vue';
import SettingsFeedTypes from '../views/settings/feed-types.vue';
import SettingsProcessors from '../views/settings/processors.vue';
import SettingsHatcheries from '../views/settings/hatcheries.vue';
import SettingsConversionRates from '../views/settings/conversion-rates.vue';
import SettingsTargetFeed from '../views/settings/target-feed.vue';
import SettingsFeedDays from '../views/settings/feeding-days.vue';
import SettingsHarvestSize from '../views/settings/harvest-size.vue';
import SettingsPurchasers from '../views/settings/purchasers.vue';
import SettingsLocations from '../views/settings/locations.vue';
import SettingsFlavors from '../views/settings/flavors.vue';
import SettingsTreatments from '../views/settings/treatments.vue';
import SettingsUsers from '../views/settings/users.vue';

// import Admin_root from '../views/admin/root-tabs.vue';
// import AdminFlavors from '../views/admin/flavors.vue';
// import AdminFeedTypes from '../views/admin/feed-types.vue';
// import AdminTreatments from '../views/admin/treatments.vue';

// import MobileFlavor from '../views/mobile/Flavor.vue';
// import MobileHarvest from '../views/mobile/Harvest.vue';
// import MobileOxygen from '../views/mobile/Oxygen.vue';
// import MobileTreat from '../views/mobile/Treat.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/feed'
  },
  {
    path: '/feed',
    icon: 'set_meal',
    name: 'Feed',
    component: Feed
  },
  {
    path: '/treat',
    icon: 'medication_liquid',
    name: 'Treat',
    component: Treat
  },
  {
    path: '/oxygen',
    icon: 'bloodtype',
    name: 'Oxygen',
    component: Oxygen
  },
  {
    path: '/flavor',
    icon: 'local_dining',
    name: 'Flavor',
    component: Flavor
  },
  {
    path: '/daily-journal',
    icon: 'assignment',
    name: 'Daily Journal',
    component: DailyJournal
  },
  {
    path: '/ponds',
    name: 'Ponds',
    component: Ponds
  },
  {
    path: '/farm-images',
    name: 'Images',
    component: Images
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/feed-dashboard/',
    name: 'Dashboard',
    icon: 'dashboard',
    component: FeedDashboard
  },
  {
    path: '/settings/',
    components: {
      default: SettingsMain
    },
    children: [
      {
        path: '',
        name: 'Settings',
        component: SettingsBins
      },
      {
        path: 'trucks',
        name: 'Settings-Trucks',
        component: SettingsTrucks
      },
      {
        path: 'ponds',
        name: 'Settings-Ponds',
        component: SettingsPonds
      },
      {
        path: 'feed-mills',
        name: 'Settings-Feed-Mills',
        component: SettingsFeedMills
      },
      {
        path: 'feed-types',
        name: 'Settings-Feed-Types',
        component: SettingsFeedTypes
      },
      {
        path: 'processors',
        name: 'Settings-Processors',
        component: SettingsProcessors
      },
      {
        path: 'hatcheries',
        name: 'Settings-hatcheries',
        component: SettingsHatcheries
      },
      {
        path: 'conversion-rates',
        name: 'Settings-Conversion-rates',
        component: SettingsConversionRates
      },
      {
        path: 'target-feed',
        name: 'Settings-Target-Feed',
        component: SettingsTargetFeed
      },
      {
        path: 'feeding-days',
        name: 'Settings-Feeding-Days',
        component: SettingsFeedDays
      },
      {
        path: 'harvest-size',
        name: 'Settings-Harvest-Size',
        component: SettingsHarvestSize
      },
      {
        path: 'purchasers',
        name: 'Settings-Purchasers',
        component: SettingsPurchasers
      },
      {
        path: 'locations',
        name: 'Settings-Locations',
        component: SettingsLocations
      },
      {
        path: 'users',
        name: 'Settings-Users',
        component: SettingsUsers
      },
      {
        path: 'flavors',
        name: 'Settings-Flavors',
        component: SettingsFlavors
      },
      {
        path: 'treatments',
        name: 'Settings-Treatments',
        component: SettingsTreatments
      }
    ]
  }
  // {
  //   path: '/admin/',
  //   components: {
  //     default: Admin_root
  //   },
  //   children: [
  //     {
  //       path: '',
  //       name: 'Admin',
  //       component: AdminFeedTypes,
  //       props: {
  //         default: true
  //       }
  //     },
  //     {
  //       path: 'flavors',
  //       name: 'Admin-Flavors',
  //       component: AdminFlavors
  //     },
  //     {
  //       path: 'treatments',
  //       name: 'Admin-Treatments',
  //       component: AdminTreatments
  //     }
  //   ]
  // }
  // {
  //   path: '/mobile/flavor',
  //   name: 'Flavor',
  //   component: MobileFlavor
  // },
  // {
  //   path: '/mobile/harvest',
  //   name: 'Harvest',
  //   component: MobileHarvest
  // },
  // {
  //   path: '/mobile/oxygen',
  //   name: 'Oxygen',
  //   component: MobileOxygen
  // },
  // {
  //   path: '/mobile/treat',
  //   name: 'Treat',
  //   component: MobileTreat
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
