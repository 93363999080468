import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import CookieUtils from './../lib/cookie-utils';
import networkPlug from './plugins/network';
import {
  addToOfflineQueue,
  deleteFromOfflineQueue,
  updateQueueAndRepublish
} from './offline';
import { cloneObj } from './../lib/helpers';
import {
  getEntryIndex,
  deleteHelper,
  setStoreItem
} from './transactionHelpers';
import connector from '@/services/mqtt/connector';
import uid from 'quasar/src/utils/uid.js';;

Vue.use(Vuex);

const emptyFarm = {
  attachments: [],
  folders: [],
  foldersWatcher: false,
  loaded: false,
  ponds: [],
  bins: [],
  dataQueryLoading: false,
  feeders: [],
  feedContracts: [],
  mills: [],
  feedTypes: [],
  hatcheries: [],
  processors: [],
  purchasers: [],
  flavors: [],
  treatments: [],
  users: [],
  feedDays: null,
  settings: null,
  subId: null,
  // Default not expired
  expiration: +new Date(),
  tfa: [],
  fcr: [],
  id: -1
};

export default new Vuex.Store({
  state: {
    currentSubFarmId: null,
    adminFarms: [],
    errorAlert: false,
    noServiceAlert: false,
    selectedLocation: null,
    showLoadingCircle: true,
    pageHeight: 500,
    OnLine: false,
    editMode: false,
    dataQueries: {
      feedContractsRecent: [],
      binEntries: {},
      feederEntries: {},
      allFeedings: []
    },
    farmIsLoaded: false,
    farmDeleteDelayed: null,
    // Once selected, farm loads with data
    farm: JSON.parse(JSON.stringify(emptyFarm)),
    // Fills with user's farms
    farmList: [],
    flavors: [],
    feedTypes: [],
    treatments: [],
    dailyJournals: [],
    user: {
      feedx_level: 'none',
      display_name: '',
      is_user_logged_in: false,
      user_email: '',
      user_id: 0,
      user_name: ''
    },
    appVersion: process.env.VUE_APP_VERSION,
    newVersion: process.env.VUE_APP_VERSION,
    selectedFarm: {
      /** @type number */
      farm_id: -1
    }
  },
  mutations: {
    SET_ADMIN_FARMS(state, payload) {
      state.adminFarms = payload.item;
    },
    SET_PAGE_HEIGHT(state, pageHeight) {
      state.pageHeight = pageHeight;
    },
    SET_SELECTED_LOCATION(state, selectedLocation) {
      state.selectedLocation = selectedLocation;
      localStorage.setItem('selectedLocation', selectedLocation);
    },
    SET_SELECTED_FARM(state, values) {
      let farm = state.farmList.find((farm) => farm.id === values.farm_id);
      if (!farm) {
        farm = state.adminFarms.find((farm) => farm.id === values.farm_id);
      }

      if (farm) {
        state.selectedFarm.farm_id = values.farm_id;
        state.farm.id = values.farm_id;
        sessionStorage.setItem(
          'savedFarm',
          JSON.stringify({
            farm_id: values.farm_id
          })
        );
      } else {
        state.farm = JSON.parse(JSON.stringify(emptyFarm));
      }
    },
    SET_USER_FARM_UNLOADED(state) {
      state.farmIsLoaded = false;
      state.farm = JSON.parse(JSON.stringify(emptyFarm));
      // Default not expired
      state.farm.expiration = +new Date();
    },
    SET_USER_FARM_LOADED(state) {
      state.farmIsLoaded = true;
      state.farm.loaded = true;
    },
    SET_ONLINE(state, payload) {
      state.OnLine = payload.OnLine;
    },
    SET_API_VERSION(state, payload) {
      state.newVersion = payload;
    },
    SET_USER_ROLE(state, payload) {
      Vue.set(state.user, 'feedx_level', payload.item);
    },
    SET_USER_DATA(state, userData) {
      if (userData === null) {
        state.user.feedx_level = 'none';
        state.user.is_user_logged_in = false;
      } else {
        state.user = userData;
      }
      state.showLoadingCircle = false;
    },
    SET_FLAVORS_ALL(state, payload) {
      Vue.set(state, 'flavors', payload.items);
    },
    SET_FLAVOR(state, payload) {
      const index = state.flavors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.flavors, index, payload.item);
      } else {
        state.flavors.push(payload.item);
      }
    },
    DEL_FLAVOR(state, payload) {
      const index = state.flavors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.flavors, index);
      }
    },
    SET_TREATMENT_ALL(state, payload) {
      Vue.set(state, 'treatments', payload.items);
    },
    SET_TREATMENT(state, payload) {
      const index = state.treatments.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.treatments, index, payload.item);
      } else {
        state.treatments.push(payload.item);
      }
    },
    DEL_TREATMENT(state, payload) {
      const index = state.treatments.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.treatments, index);
      }
    },
    SET_DAILY_JOURNALS_ALL(state, payload) {
      Vue.set(state, 'dailyJournals', payload.items);
    },
    SET_DAILY_JOURNALS(state, payload) {
      const index = state.dailyJournals.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.dailyJournals, index, payload.item);
      } else {
        state.dailyJournals.push(payload.item);
      }
    },
    DEL_DAILY_JOURNAL(state, payload) {
      const index = state.dailyJournals.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.dailyJournals, index);
      }
    },
    SET_FARM_LIST_ALL(state, payload) {
      Vue.set(state, 'farmList', payload.items);
      // Auto select if not
      if (state.farmList && state.farmList.length > 0) {
        const storage = sessionStorage.getItem('savedFarm');
        const cached = storage ? JSON.parse(storage) : null;
        if (cached === null) {
          state.farm.id = state.selectedFarm.farm_id = state.farmList[0].id;
        } else {
          if (state.farmList.find((farm) => farm.id === cached.farm_id)) {
            state.farm.id = state.selectedFarm.farm_id = cached.farm_id;
          } else {
            state.farm.id = state.selectedFarm.farm_id = state.farmList[0].id;
          }
        }
      }
    },
    SET_FARM_LIST(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farmList, index, payload.item);
      } else {
        state.farmList.push(payload.item);
      }
    },
    DEL_FARM_LIST(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        if (state.farmList[index].id === state.selectedFarm.farm_id) {
          state.farmDeleteDelayed = state.farmList[index];
        } else {
          Vue.delete(state.farmList, index);
        }
      }
    },
    DEL_FARM_DELAYED(state, payload) {
      state.farm = JSON.parse(JSON.stringify(emptyFarm));
      const index = state.farmList.findIndex(
        (e) => e.id === state.farmDeleteDelayed.id
      );
      if (index !== -1) {
        Vue.delete(state.farmList, index);
        state.farmDeleteDelayed = null;
      }
    },
    SET_BINS_ALL(state, payload) {
      Vue.set(state.farm, 'bins', payload.items);
    },
    SET_BIN(state, payload) {
      const index = state.farm.bins.findIndex(
        (element) => element.id === payload.itemId
      );

      if (index !== -1) {
        Vue.set(state.farm.bins, index, payload.item);
      } else {
        state.farm.bins.push(payload.item);
      }
    },
    DEL_BIN(state, payload) {
      const index = state.farm.bins.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.bins, index);
      }
    },
    SET_PONDS_ALL(state, payload) {
      Vue.set(state.farm, 'ponds', payload.items);
    },
    SET_POND(state, payload) {
      const index = state.farm.ponds.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.ponds, index, payload.item);
      } else {
        state.farm.ponds.push(payload.item);
      }
    },
    DEL_POND(state, payload) {
      const index = state.farm.ponds.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.ponds, index);
      }
    },
    SET_FEEDERS_ALL(state, payload) {
      Vue.set(state.farm, 'feeders', payload.items);
    },
    SET_FEEDER(state, payload) {
      const index = state.farm.feeders.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.feeders, index, payload.item);
      } else {
        state.farm.feeders.push(payload.item);
      }
    },
    DEL_FEEDER(state, payload) {
      const index = state.farm.feeders.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.feeders, index);
      }
    },
    SET_CONTRACT_ALL(state, payload) {
      Vue.set(state.farm, 'feedContracts', payload.items);
    },
    SET_CONTRACT(state, payload) {
      const index = getEntryIndex(state.farm.feedContracts, payload.item);
      const queryIndex = getEntryIndex(
        state.dataQueries.feedContractsRecent,
        payload.item
      );

      if (payload.item.date_closed) {
        deleteHelper(Vue, state.farm.feedContracts, payload.itemId);
      } else if (index !== -1) {
        Vue.set(state.farm.feedContracts, index, payload.item);
      } else {
        state.farm.feedContracts.push(payload.item);
      }

      if (queryIndex !== -1) {
        Vue.set(
          state.dataQueries.feedContractsRecent,
          queryIndex,
          payload.item
        );
      } else {
        state.dataQueries.feedContractsRecent.push(payload.item);
      }
    },
    DEL_CONTRACT(state, payload) {
      deleteHelper(Vue, state.farm.feedContracts, payload.itemId);
      deleteHelper(Vue, state.dataQueries.feedContractsRecent, payload.itemId);
    },
    SET_MILLS_ALL(state, payload) {
      Vue.set(state.farm, 'mills', payload.items);
    },
    SET_MILL(state, payload) {
      const index = state.farm.mills.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.mills, index, payload.item);
      } else {
        state.farm.mills.push(payload.item);
      }
    },
    DEL_MILL(state, payload) {
      const index = state.farm.mills.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.mills, index);
      }
    },
    SET_FARM_FEEDTYPES_ALL(state, payload) {
      Vue.set(state.farm, 'feedTypes', payload.items);
    },
    SET_FARM_FEEDTYPE(state, payload) {
      const index = state.farm.feedTypes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.feedTypes, index, payload.item);
      } else {
        state.farm.feedTypes.push(payload.item);
      }
    },
    DEL_FARM_FEEDTYPE(state, payload) {
      const index = state.farm.feedTypes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.feedTypes, index);
      }
    },
    SET_FEEDTYPES_ALL(state, payload) {
      Vue.set(state, 'feedTypes', payload.items);
    },
    SET_FEEDTYPE(state, payload) {
      const index = state.feedTypes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.feedTypes, index, payload.item);
      } else {
        state.feedTypes.push(payload.item);
      }
    },
    DEL_FEEDTYPE(state, payload) {
      const index = state.feedTypes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.feedTypes, index);
      }
    },
    SET_HATCHERIES_ALL(state, payload) {
      Vue.set(state.farm, 'hatcheries', payload.items);
    },
    SET_HATCHERY(state, payload) {
      const index = state.farm.hatcheries.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.hatcheries, index, payload.item);
      } else {
        state.farm.hatcheries.push(payload.item);
      }
    },
    DEL_HATCHERY(state, payload) {
      const index = state.farm.hatcheries.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.hatcheries, index);
      }
    },
    SET_PROCESSORS_ALL(state, payload) {
      Vue.set(state.farm, 'processors', payload.items);
    },
    SET_PROCESSOR(state, payload) {
      const index = state.farm.processors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.processors, index, payload.item);
      } else {
        state.farm.processors.push(payload.item);
      }
    },
    DEL_PROCESSOR(state, payload) {
      const index = state.farm.processors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.processors, index);
      }
    },
    SET_PURCHASERS_ALL(state, payload) {
      Vue.set(state.farm, 'purchasers', payload.items);
    },
    SET_PURCHASER(state, payload) {
      const index = state.farm.purchasers.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.purchasers, index, payload.item);
      } else {
        state.farm.purchasers.push(payload.item);
      }
    },
    DEL_PURCHASER(state, payload) {
      const index = state.farm.purchasers.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.purchasers, index);
      }
    },
    SET_FCR_ALL(state, payload) {
      Vue.set(state.farm, 'fcr', payload.items);
    },
    SET_FCR(state, payload) {
      const index = state.farm.fcr.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.fcr, index, payload.item);
      } else {
        state.farm.fcr.push(payload.item);
      }
    },
    DEL_FCR(state, payload) {
      const index = state.farm.fcr.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.fcr, index);
      }
    },
    SET_TFA_ALL(state, payload) {
      Vue.set(state.farm, 'tfa', payload.items);
    },
    SET_TFA(state, payload) {
      const index = state.farm.tfa.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.tfa, index, payload.item);
      } else {
        state.farm.tfa.push(payload.item);
      }
    },
    DEL_TFA(state, payload) {
      const index = state.farm.tfa.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.tfa, index);
      }
    },
    SET_FARM_FLAVOR_ALL(state, payload) {
      Vue.set(state.farm, 'flavors', payload.items);
    },
    SET_FARM_FLAVOR(state, payload) {
      const index = state.farm.flavors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.flavors, index, payload.item);
      } else {
        state.farm.flavors.push(payload.item);
      }
    },
    DEL_FARM_FLAVOR(state, payload) {
      const index = state.farm.flavors.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.flavors, index);
      }
    },
    SET_FARM_TREATMENT_ALL(state, payload) {
      Vue.set(state.farm, 'treatments', payload.items);
    },
    SET_FARM_TREATMENT(state, payload) {
      const index = state.farm.treatments.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.treatments, index, payload.item);
      } else {
        state.farm.treatments.push(payload.item);
      }
    },
    DEL_FARM_TREATMENT(state, payload) {
      const index = state.farm.treatments.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.treatments, index);
      }
    },
    SET_FARM_SETTINGS(state, payload) {
      Vue.set(state.farm, 'settings', payload.item);
    },
    SET_FARM_EXPIRATION(state, payload) {
      Vue.set(state.farm, 'expiration', payload.item);
    },
    SET_FEED_DAYS(state, payload) {
      Vue.set(state.farm, 'feedDays', payload.item);
    },
    SET_FARM_USERS_ALL(state, payload) {
      Vue.set(state.farm, 'users', payload.items);
    },
    SET_FARM_USER(state, payload) {
      const index = state.farm.users.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.users, index, payload.item);
      } else {
        state.farm.users.push(payload.item);
      }
    },
    DEL_FARM_USER(state, payload) {
      const index = state.farm.users.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.users, index);
      }
    },
    SET_FISH_MOVE(state, payload) {
      const item = payload.item;

      if (item?.storeInfo?.transferPonds ?? false) {
        for (const pond of item.storeInfo.transferPonds) {
          const index = state.farm.ponds.findIndex((x) => x.id === pond.id);

          if (index !== -1) {
            Vue.set(state.farm.ponds, index, pond);
          }
        }

        delete item.storeInfo;
      }

      const index = state.farm.ponds.findIndex((x) => x.id === item.id);
      if (index !== -1) {
        Vue.set(state.farm.ponds, index, item);
      }
    },
    SET_ATTACHMENT_ITEM(state, payload) {
      if (payload.item.storeInfo.parentType === 'fishmove') {
        const index = state.farm.ponds.findIndex(
          (x) => x.id === payload.item.storeInfo.pondId
        );

        if (index !== -1) {
          Vue.set(state.farm.ponds, index, payload.item.updateObj);
        }
      }

      if (payload.item.storeInfo.parentType === 'binDelivery') {
        const index = state.farm.bins.findIndex(
          (x) => x.grain_id === payload.item.storeInfo.binGrainId
        );

        state.farm.bins[index].deliveries.splice(0);
        state.farm.bins[index].deliveries = payload.item.updateObj;
      }

      if (payload.item.storeInfo.parentType === 'farmFolders') {
        state.farm.folders.splice(0);
        state.farm.folders = payload.item.updateObj.farmFolders;

        state.farm.attachments.splice(0);
        state.farm.attachments = payload.item.updateObj.farmAttachments;

        state.farm.foldersWatcher = !state.farm.foldersWatcher;
      }

      if (payload.item.storeInfo.parentType === 'dailyJournal') {
        const index = state.dailyJournals.findIndex(
          (x) => x.id === payload.item.storeInfo.dailyJournalId
        );

        Vue.set(state.dailyJournals, index, payload.item.updateObj);

        // state.dailyJournals[index].attachments.splice(0);
        // state.dailyJournals[index].attachments = payload.item.updateObj;
      }
    },
    // set entries
    SET_STORE_ITEM(state, payload) {
      const item = payload.item;
      // contract delete does not return item
      if (!item) {
        return;
      }

      setStoreItem[item.storeInfo.category](item, state, Vue);
    },
    SET_DATA_QUERY_LOADING(state, loadingObj) {
      Vue.set(state.dataQueries, loadingObj.queryProp, []);
      Vue.set(state, 'dataQueryLoading', loadingObj.isLoading);
      Vue.set(state, 'subId', loadingObj.subId);
    },
    SET_QUERIED_DATA(state, payload) {
      const storeInfo = payload.item.storeInfo;
      if (storeInfo.subId !== state.subId) {
        return;
      }

      Vue.set(state.dataQueries, storeInfo.queryProp, payload.item.result);

      Vue.set(state, 'dataQueryLoading', false);
      connector.unSubscribe([`farm/${state.farm.id}/queryData/#`]);
    },
    SET_ERROR_ALERT(state, errorAlert) {
      state.errorAlert = errorAlert;
    },
    SET_NO_SERVICE_ALERT(state, noServiceAlert) {
      state.noServiceAlert = noServiceAlert;
    }
  },
  actions: {
    confirmResult({ commit, dispatch }, result) {
      if (result.sent && result.reasonCode !== 0) {
        dispatch('setErrorAlert', true);
      }

      if (!result.sent) {
        dispatch('setNoServiceAlert', true);
      }
    },
    async subscribeInitial({ commit }, userId) {
      return connector.subscribe([
        `user/${userId}/feedx_level/`,
        `user/${userId}/farms/#`,
        `user/${userId}/adminFarms/#`,
        'feedTypes/#',
        'flavors/#',
        'treatments/#'
      ]);
    },
    subscribeFarm({ commit, getters, state }, selectedFarm) {
      // prevent resubscribing to the same farm
      if (state.currentSubFarmId === selectedFarm.farm_id) {
        return;
      }

      state.currentSubFarmId = selectedFarm.farm_id;

      const subs = [
        // Attachments
        `farm/${selectedFarm.farm_id}/attachments/`,
        // Contracts
        `farm/${selectedFarm.farm_id}/feedContracts/#`,
        // Feed Entries
        `farm/${selectedFarm.farm_id}/binEntries/#`,
        `farm/${selectedFarm.farm_id}/feederEntries/#`,
        `farm/${selectedFarm.farm_id}/pondEntries/#`,
        // Pond Entries
        `farm/${selectedFarm.farm_id}/fishMove/#`,
        `farm/${selectedFarm.farm_id}/treatPond/#`,
        `farm/${selectedFarm.farm_id}/oxygenPond/#`,
        `farm/${selectedFarm.farm_id}/flavorPond/#`,
        // CRUD
        `farm/${selectedFarm.farm_id}/tfa/#`,
        `farm/${selectedFarm.farm_id}/fcr/#`,
        `farm/${selectedFarm.farm_id}/bins/#`,
        `farm/${selectedFarm.farm_id}/ponds/#`,
        `farm/${selectedFarm.farm_id}/feeders/#`,
        `farm/${selectedFarm.farm_id}/mills/#`,
        `farm/${selectedFarm.farm_id}/hatcheries/#`,
        `farm/${selectedFarm.farm_id}/processors/#`,
        `farm/${selectedFarm.farm_id}/purchasers/#`,
        `farm/${selectedFarm.farm_id}/feedTypes/#`,
        `farm/${selectedFarm.farm_id}/flavors/#`,
        `farm/${selectedFarm.farm_id}/treatments/#`,
        `farm/${selectedFarm.farm_id}/feed_days/#`,
        `farm/${selectedFarm.farm_id}/dailyJournals/#`,
        `farm/${selectedFarm.farm_id}/settings/#`,
        `farm/${selectedFarm.farm_id}/users/#`,
        `farm/${selectedFarm.farm_id}/subscription/`
      ];
      connector.subscribe(subs).then((results) => {
        commit('SET_USER_FARM_LOADED');
      });
    },
    unSubscribeFarm({ commit, getters }, selectedFarm) {
      // const loc = selectedFarm.location_id || null;
      // const user_id = getters.userId;
      const unSubs = [
        // Attachments
        `farm/${selectedFarm.farm_id}/attachments/`,
        // Contracts
        `farm/${selectedFarm.farm_id}/feedContracts/#`,
        // Feed Entries
        `farm/${selectedFarm.farm_id}/binEntries/#`,
        `farm/${selectedFarm.farm_id}/feederEntries/#`,
        `farm/${selectedFarm.farm_id}/pondEntries/#`,
        // Pond Entries
        `farm/${selectedFarm.farm_id}/fishMove/#`,
        `farm/${selectedFarm.farm_id}/treatPond/#`,
        `farm/${selectedFarm.farm_id}/oxygenPond/#`,
        `farm/${selectedFarm.farm_id}/flavorPond/#`,
        // CRUD
        `farm/${selectedFarm.farm_id}/tfa/#`,
        `farm/${selectedFarm.farm_id}/fcr/#`,
        `farm/${selectedFarm.farm_id}/bins/#`,
        `farm/${selectedFarm.farm_id}/ponds/#`,
        `farm/${selectedFarm.farm_id}/feeders/#`,
        `farm/${selectedFarm.farm_id}/mills/#`,
        `farm/${selectedFarm.farm_id}/hatcheries/#`,
        `farm/${selectedFarm.farm_id}/processors/#`,
        `farm/${selectedFarm.farm_id}/purchasers/#`,
        `farm/${selectedFarm.farm_id}/feedTypes/#`,
        `farm/${selectedFarm.farm_id}/flavors/#`,
        `farm/${selectedFarm.farm_id}/treatments/#`,
        `farm/${selectedFarm.farm_id}/feed_days/#`,
        `farm/${selectedFarm.farm_id}/dailyJournals/#`,
        `farm/${selectedFarm.farm_id}/settings/#`,
        `farm/${selectedFarm.farm_id}/users/#`,
        `farm/${selectedFarm.farm_id}/subscription/`
      ];
      connector.unSubscribe(unSubs).then((results) => {
        console.log('unSubscribeFarm', results, unSubs);
      });
    },
    logout({ commit }) {
      commit('SET_USER_DATA', null);
      return axios
        .post(process.env.VUE_APP_ACCOUNTS_LOGIN + '/api/logout', null)
        .then(() => {
          CookieUtils.redirectToAccounts();
        })
        .catch(() => {
          // Some issue with the server, we will delete our client side cookie
          CookieUtils.deleteLocalCookie();
          CookieUtils.redirectToAccounts();
        });
    },
    setPageHeight({ commit }, pageHeight) {
      commit('SET_PAGE_HEIGHT', pageHeight);
    },
    setSelectedFarm({ commit, dispatch, getters }, values) {
      if (getters.farmIsLoaded) {
        // Unsubscribe from this farm
        commit('SET_USER_FARM_UNLOADED');
        // commit('SET_POND_CLEAN_FARM');
        dispatch('unSubscribeFarm', getters.selectedFarmInfo);
      }
      if (getters.farmDeleteDelayed !== null) {
        commit('DEL_FARM_DELAYED');
      }
      commit('SET_SELECTED_FARM', values);
    },
    setSelectedLocation({ commit }, selectedLocation) {
      commit('SET_SELECTED_LOCATION', selectedLocation);
    },
    setErrorAlert({ commit }, errorAlert) {
      commit('SET_ERROR_ALERT', errorAlert);
    },
    setNoServiceAlert({ commit }, noServiceAlert) {
      commit('SET_NO_SERVICE_ALERT', noServiceAlert);
    },

    // Data Queries
    async queryData({ commit, getters }, query) {
      query.storeInfo.subId = uid();
      const info = query.storeInfo;

      const loadingObj = {
        subId: info.subId,
        queryProp: info.queryProp,
        isLoading: true
      };

      commit('SET_DATA_QUERY_LOADING', loadingObj);

      const farmId = getters.selectedFarm.id;
      connector.subscribe([`farm/${farmId}/queryData/#`]);

      return connector.publish(
        `farm/${farmId}/queryData/0/${info.action}/`,
        JSON.stringify(query)
      );
    },

    // Entries
    async publish({ commit, state, dispatch }, item) {
      try {
        const info = item.storeInfo;

        const mutationPayload = { item: cloneObj(item), itemId: item.id };

        commit('SET_STORE_ITEM', mutationPayload);

        let results = { sent: false };
        let pending = true;

        setTimeout(() => {
          if (pending) {
            updateQueueAndRepublish();
          }
        }, 1000);

        addToOfflineQueue(item);

        if (state.OnLine) {
          results = await connector
            .publish(
              `farm/${info.farmId}/${info.category}/${item.id}/${info.action}/`,
              JSON.stringify(item)
            )
            .then((results) => {
              pending = false;
              return results;
            });
        }

        if (results.sent) {
          deleteFromOfflineQueue(item.guid);
        }
        updateQueueAndRepublish();

        if (results.reasonCode !== 0 && results.sent) {
          console.error(results);
          dispatch('setErrorAlert', true);
        }

        if (results.reasonCode !== 0) {
          console.error(results);
        }
      } catch (err) {
        console.error(err);
        dispatch('setErrorAlert', true);
      }
    },

    // Fish Move
    // Cannot be done offline because the growth engine has to rerun
    async publishFishMove({ commit, getters }, fishMove) {
      const farmId = getters.selectedFarm.id;
      return connector
        .publish(
          `farm/${farmId}/fishMove/${fishMove.id}/${fishMove.storeInfo.action}/`,
          JSON.stringify(fishMove)
        )
        .then(async (results) => {
          const success = results.sent && results.reasonCode === 0;

          if (!success) {
            console.error(results);
          }

          return success;
        });
    },

    async publishAttachment({ commit, getters }, attachment) {
      const farmId = getters.selectedFarm.id;
      return connector
        .publish(`farm/${farmId}/attachments/`, JSON.stringify(attachment))
        .then(async (results) => {
          const success = results.sent && results.reasonCode === 0;

          if (!success) {
            console.error(results);
          }

          return success;
        });
    },

    // CRUD
    async deleteBin({ commit, getters }, { bin }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/bins/${bin.id}/delete/`,
        JSON.stringify(bin)
      );
    },
    async createBin({ commit, getters }, { bin }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/bins/0/create/`,
        JSON.stringify(bin)
      );
    },
    async updateBin({ commit, getters }, { bin }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/bins/${bin.id}/update/`,
        JSON.stringify(bin)
      );
    },
    async deleteFeeder({ commit, getters }, { feeder }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feeders/${feeder.id}/delete/`,
        JSON.stringify(feeder)
      );
    },
    async createFeeder({ commit, getters }, { feeder }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feeders/0/create/`,
        JSON.stringify(feeder)
      );
    },
    async updateFeeder({ commit, getters }, { feeder }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feeders/${feeder.id}/update/`,
        JSON.stringify(feeder)
      );
    },
    async deletePond({ commit, getters }, { pond }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/ponds/${pond.id}/delete/`,
        JSON.stringify(pond)
      );
    },
    async createPond({ commit, getters }, { pond }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/ponds/0/create/`,
        JSON.stringify(pond)
      );
    },
    async updatePond({ commit, getters }, { pond }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/ponds/${pond.id}/update/`,
        JSON.stringify(pond)
      );
    },
    async deleteTFA({ commit, getters }, { tfa }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/tfa/${tfa.id}/delete/`,
        JSON.stringify(tfa)
      );
    },
    async createTFA({ commit, getters }, { tfa }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/tfa/0/create/`,
        JSON.stringify(tfa)
      );
    },
    async updateTFA({ commit, getters }, { tfa }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/tfa/${tfa.id}/update/`,
        JSON.stringify(tfa)
      );
    },
    async deleteFCR({ commit, getters }, { fcr }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/fcr/${fcr.id}/delete/`,
        JSON.stringify(fcr)
      );
    },
    async createFCR({ commit, getters }, { fcr }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/fcr/0/create/`,
        JSON.stringify(fcr)
      );
    },
    async updateFCR({ commit, getters }, { fcr }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/fcr/${fcr.id}/update/`,
        JSON.stringify(fcr)
      );
    },
    async deleteFeedMill({ commit, getters }, { mill }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/mills/${mill.id}/delete/`,
        JSON.stringify(mill)
      );
    },
    async createFeedMill({ commit, getters }, { mill }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/mills/0/create/`,
        JSON.stringify(mill)
      );
    },
    async updateFeedMill({ commit, getters }, { mill }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/mills/${mill.id}/update/`,
        JSON.stringify(mill)
      );
    },
    async deleteFeedType({ commit, getters }, { feedType }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feedTypes/${feedType.id}/delete/`,
        JSON.stringify(feedType)
      );
    },
    async createFeedType({ commit, getters }, { feedType }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feedTypes/0/create/`,
        JSON.stringify(feedType)
      );
    },
    async updateFeedType({ commit, getters }, { feedType }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feedTypes/${feedType.id}/update/`,
        JSON.stringify(feedType)
      );
    },
    async deleteGlobalFeedType({ commit, getters }, { feedType }) {
      return connector.publish(
        `feedTypes/${feedType.id}/delete/`,
        JSON.stringify(feedType)
      );
    },
    async createGlobalFeedType({ commit, getters }, { feedType }) {
      return connector.publish('feedTypes/0/create/', JSON.stringify(feedType));
    },
    async updateGlobalFeedType({ commit, getters }, { feedType }) {
      return connector.publish(
        `feedTypes/${feedType.id}/update/`,
        JSON.stringify(feedType)
      );
    },
    async deleteProcessor({ commit, getters }, { processor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/processors/${processor.id}/delete/`,
        JSON.stringify(processor)
      );
    },
    async createProcessor({ commit, getters }, { processor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/processors/0/create/`,
        JSON.stringify(processor)
      );
    },
    async updateProcessor({ commit, getters }, { processor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/processors/${processor.id}/update/`,
        JSON.stringify(processor)
      );
    },
    async deleteHatchery({ commit, getters }, { hatchery }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/hatcheries/${hatchery.id}/delete/`,
        JSON.stringify(hatchery)
      );
    },
    async createHatchery({ commit, getters }, { hatchery }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/hatcheries/0/create/`,
        JSON.stringify(hatchery)
      );
    },
    async updateHatchery({ commit, getters }, { hatchery }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/hatcheries/${hatchery.id}/update/`,
        JSON.stringify(hatchery)
      );
    },
    async deletePurchaser({ commit, getters }, { purchaser }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/purchasers/${purchaser.id}/delete/`,
        JSON.stringify(purchaser)
      );
    },
    async createPurchaser({ commit, getters }, { purchaser }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/purchasers/0/create/`,
        JSON.stringify(purchaser)
      );
    },
    async updatePurchaser({ commit, getters }, { purchaser }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/purchasers/${purchaser.id}/update/`,
        JSON.stringify(purchaser)
      );
    },
    async deleteFlavor({ commit, getters }, { flavor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/flavors/${flavor.id}/delete/`,
        JSON.stringify(flavor)
      );
    },
    async createFlavor({ commit, getters }, { flavor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/flavors/0/create/`,
        JSON.stringify(flavor)
      );
    },
    async updateFlavor({ commit, getters }, { flavor }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/flavors/${flavor.id}/update/`,
        JSON.stringify(flavor)
      );
    },
    async deleteGlobalFlavor({ commit, getters }, { flavor }) {
      return connector.publish(
        `flavors/${flavor.id}/delete/`,
        JSON.stringify(flavor)
      );
    },
    async createGlobalFlavor({ commit, getters }, { flavor }) {
      return connector.publish('flavors/0/create/', JSON.stringify(flavor));
    },
    async updateGlobalFlavor({ commit, getters }, { flavor }) {
      return connector.publish(
        `flavors/${flavor.id}/update/`,
        JSON.stringify(flavor)
      );
    },
    async deleteTreatment({ commit, getters }, { treatment }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/treatments/${treatment.id}/delete/`,
        JSON.stringify(treatment)
      );
    },
    async createTreatment({ commit, getters }, { treatment }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/treatments/0/create/`,
        JSON.stringify(treatment)
      );
    },
    async updateTreatment({ commit, getters }, { treatment }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/treatments/${treatment.id}/update/`,
        JSON.stringify(treatment)
      );
    },
    async deleteGlobalTreatment({ commit, getters }, { treatment }) {
      return connector.publish(
        `treatments/${treatment.id}/delete/`,
        JSON.stringify(treatment)
      );
    },
    async createGlobalTreatment({ commit, getters }, { treatment }) {
      return connector.publish(
        'treatments/0/create/',
        JSON.stringify(treatment)
      );
    },
    async updateGlobalTreatment({ commit, getters }, { treatment }) {
      return connector.publish(
        `treatments/${treatment.id}/update/`,
        JSON.stringify(treatment)
      );
    },
    async updateFeedDays({ commit, getters }, { feedDays }) {
      const farmId = getters.selectedFarm.id;
      return connector.publish(
        `farm/${farmId}/feed_days/update/`,
        JSON.stringify(feedDays)
      );
    },
    async updateFarmSettings({ commit, getters, dispatch }, { farmSettings }) {
      const farmId = getters.selectedFarm.id;
      farmSettings.storeInfo = { farmId };
      const result = await connector.publish(
        `farm/${farmId}/settings/update/`,
        JSON.stringify(farmSettings)
      );

      dispatch('confirmResult', result);
    }
  },
  modules: {},
  getters: {
    farmDeleteDelayed(state) {
      return state.farmDeleteDelayed;
    },
    subscriptionExpired(state) {
      return state.farm.expiration < Date.now() / 1000;
    },
    farmIsLoaded(state) {
      return state.farmIsLoaded;
    },
    selectedFarmInfo(state) {
      return {
        farm_id: state.selectedFarm.farm_id
      };
    },
    selectedFarm(state) {
      let farm = state.farmList.find(
        (farm) => farm.id === state.selectedFarm.farm_id
      );

      if (!farm) {
        farm = state.adminFarms.find(
          (farm) => farm.id === state.selectedFarm.farm_id
        );
      }

      return farm;
    },
    userFarmRole(state) {
      const farm = state.farmList.find(
        (farm) => farm.id === state.selectedFarm.farm_id
      );
      return farm ? farm.user_role : 'none';
    }
  },
  plugins: [networkPlug()]
});
